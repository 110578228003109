import _ from 'underscore';
import BaseCollection from '../../../base/collection/base';
import CabinTypeModel from '../model/cabin-type';
import contentApiConfig from '../../../config/content-api';
import {NETMATCH_PRICEMODEL_FEELGOOD, NETMATCH_PRICEMODEL_FEELGOODPLUS, NETMATCH_PRICEMODEL_FLEX} from '../../../config/constants';

/**
 * https://api.tuicruises.com/pcl-test/trips/MSS2510SEE/cabinTypesAndCategories
 *
 * Keys in use:
 * - images
 * - cabinTypeInterior
 * - exteriorType
 * - interiorSizeInfo
 * - maxOccupation
 * - cabinTypeSpecifics
 * - categoryTypeCode
 * - exteriorSizeInfo
 * - floorplanImageURLs
 * - cabinTypeAdditionalInterior
 * - panoramas
 * - otdsCabinTypeName
 * - cabinTypeName
 * - minOccupation
 *
 * Not in use:
 * - description
 *
 * cabinTypeInterior: [alle equipments, die mit TD beginnen]
 * cabinTypeSpecifics: [alle equipments, die mit TB beginnen]
 * cabinTypeAdditionalInterior: [alle equipments, die mit TS beginnen]
 */
export default class CabinTypesCollection extends BaseCollection {
    constructor (models, options = {}) {
        super(models, {
            model: CabinTypeModel, ...options
        });
    }

    prepareUrlAndFetch (tripCode) {
        // this.url = contentApiConfig.cabinTypesAndCategoriesByeTripCodeURL.replace('{tripCode}', tripCode);
        this.url = contentApiConfig.i18nUrlCabinTypesAndCategories(tripCode);

        return this.fetch();
    }

    parse (response) {
        const getEquipmentForCodePrefix = (value, codePrefix) => {
            return (value.cabinType.equipments || []).filter((item) => item.code.startsWith(codePrefix)).map((item) => item.name);
        };

        return response.map((v) => {
            return {
                'images': v.cabinType.images.map((i) => ({
                    imageURL: i,
                    description: ''
                })),
                'cabinTypeInterior': getEquipmentForCodePrefix(v, 'TD'),
                'exteriorType': v.cabinType.exteriorType,
                'interiorSizeInfo': v.cabinType.interiorSizeInfo,
                'maxOccupation': Math.max(...v.cabinCategories.map((c) => c.maxOccupation)),
                'cabinTypeSpecifics': getEquipmentForCodePrefix(v, 'TB'),
                'description': v.cabinType.description,
                'categoryTypeCode': v.cabinType.code,
                'exteriorSizeInfo': v.cabinType.exteriorSizeInfo,
                'floorplanImageURLs': v.cabinType.floorplans,
                'cabinTypeAdditionalInterior': getEquipmentForCodePrefix(v, 'TS'),
                'panoramas': v.cabinType.panoramas.map((i) => ({
                    previewImageURL: i.previewImage,
                    panoramaURL: i.panoramaUrl
                })),
                'otdsCabinTypeName': v.cabinType.otdsName,
                'cabinTypeName': v.cabinType.name,
                'minOccupation': Math.min(...v.cabinCategories.map((c) => c.minOccupation)),
                'cabinCategories': v.cabinCategories
            };
        });
    }

    getJSON () {
        return this.toJSON();
    }

    getCabinCabinCategories () {
        const cabin = [];
        _.each(this.models, (item) => {
            if (item.cabinCategories) {
                const tmp = [];
                Object.keys(item.cabinCategories.attributes).forEach((key) => {
                    tmp.push(item.cabinCategories.attributes[key]);
                });

                cabin.push({
                    categoryTypeCode: item.id,
                    cabinCategories: tmp,
                    cabinTypeName: item.get('cabinTypeName')
                });
            }
        });
        return cabin;
    }


    /**
     * Return the merged cabin type details from the content and the ibe api
     */
    getAvailableCabinTypesByPriceModel (ibeCabinTypeOptions) {
        const contentApiCabinTypes = this.toJSON();

        const formatContentApiCabinType = {};

        contentApiCabinTypes.forEach((value) => {
            formatContentApiCabinType[value.categoryTypeCode] = value;
        });

        return ibeCabinTypeOptions.reduce((availabiltyData, ibeCabinTypeOption) => {
            const categoryTypeCode = ibeCabinTypeOption.categoryTypeCode;
            const priceModel = ibeCabinTypeOption.model;
            if (!availabiltyData[priceModel]) {
                availabiltyData[priceModel] = [];
            }

            availabiltyData[priceModel].push({
                ...ibeCabinTypeOption,
                content: formatContentApiCabinType[categoryTypeCode]
            });

            // filter by isBlocked
            availabiltyData[priceModel].sort((a, b) => {
                if (a.isBlocked === true && b.isBlocked === true) {
                    return 0;
                }
                if (a.isBlocked === false && b.isBlocked === false) {
                    return 0;
                }
                if (a.isBlocked === true && b.isBlocked === false) {
                    return 1;
                }
                if (a.isBlocked === false && b.isBlocked === true) {
                    return -1;
                }
                return 0;
            });
            return availabiltyData;
        }, {});
    }

    getAvailableCabinTypes (ibeCabinTypeOptions) {
        const groupedIbeCabinTypes = ibeCabinTypeOptions.reduce((availabiltyData, ibeCabinTypeOption) => {
            const categoryTypeCode = ibeCabinTypeOption.categoryTypeCode;
            if (!availabiltyData[categoryTypeCode]) {
                availabiltyData[categoryTypeCode] = {};
            }
            const priceModel = ibeCabinTypeOption.model;
            availabiltyData[categoryTypeCode][priceModel] = ibeCabinTypeOption;
            return availabiltyData;
        }, {});

        const contentApiCabinTypes = this.toJSON();
        contentApiCabinTypes.forEach(contentApiCabin => {
            const categoryTypeCode = contentApiCabin.categoryTypeCode;
            const ibeCabinType = groupedIbeCabinTypes[categoryTypeCode] || {};
            contentApiCabin.ibePriceModels = {
                [NETMATCH_PRICEMODEL_FLEX]: ibeCabinType[NETMATCH_PRICEMODEL_FLEX],
                [NETMATCH_PRICEMODEL_FEELGOOD]: ibeCabinType[NETMATCH_PRICEMODEL_FEELGOOD],
                [NETMATCH_PRICEMODEL_FEELGOODPLUS]: ibeCabinType[NETMATCH_PRICEMODEL_FEELGOODPLUS]
            };
        });
        return contentApiCabinTypes;
    }


    getCabinModelByCategoryTypeCode (code) {
        let cabin;
        _.each(this.models, (item) => {
            if (item.id === code) {
                cabin = item;
            }
        });
        return cabin;
    }
}

/*
@TODO: Remove this later
NEW:
[
    {
        "cabinType": {
            "code": "SM",
            "name": "Himmel & Meer Suite",
            "description": "Mit bodentiefen Fenstern und einer eigenen Dachterrasse bietet diese Suite auf 2 Decks viel Platz und Individualität.",
            "interiorSizeInfo": "40",
            "exteriorSizeInfo": "29",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/himmel-meer-suite",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                },
                {
                    "code": "TS02",
                    "name": "Zugang zur X-Lounge"
                },
                {
                    "code": "TS04",
                    "name": "kostenfreier Zeitungsservice"
                },
                {
                    "code": "TS05",
                    "name": "Excellence-Service"
                },
                {
                    "code": "TS06",
                    "name": "kostenfreier Internetzugang"
                },
                {
                    "code": "TS07",
                    "name": "separater Check-In"
                },
                {
                    "code": "TS08",
                    "name": "kostenfreie Getränke in der Minibar"
                },
                {
                    "code": "TD10",
                    "name": "Hängematte"
                },
                {
                    "code": "TD12",
                    "name": "Balkon/Veranda mit Tisch und 4 Stühlen"
                },
                {
                    "code": "TB16",
                    "name": "Dachterrasse teilweise mit Sonnenschutz"
                },
                {
                    "code": "TB19",
                    "name": "Sitz- und Couchecke"
                },
                {
                    "code": "TD17",
                    "name": "2 Liegestühle"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "SUHO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "SD",
            "name": "Diamant Suite",
            "description": "Diese Suite bietet Platz für 6 Personen und verfügt über ein zusätzliches Schlafzimmer. Die große Veranda direkt am Diamanten bietet viel Komfort.",
            "interiorSizeInfo": "42",
            "exteriorSizeInfo": "47",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/diamant-suite",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                },
                {
                    "code": "TS02",
                    "name": "Zugang zur X-Lounge"
                },
                {
                    "code": "TS04",
                    "name": "kostenfreier Zeitungsservice"
                },
                {
                    "code": "TS05",
                    "name": "Excellence-Service"
                },
                {
                    "code": "TS06",
                    "name": "kostenfreier Internetzugang"
                },
                {
                    "code": "TS07",
                    "name": "separater Check-In"
                },
                {
                    "code": "TS08",
                    "name": "kostenfreie Getränke in der Minibar"
                },
                {
                    "code": "TD10",
                    "name": "Hängematte"
                },
                {
                    "code": "TD12",
                    "name": "Balkon/Veranda mit Tisch und 4 Stühlen"
                },
                {
                    "code": "TB18",
                    "name": "optisch getrennter Wohnraum"
                },
                {
                    "code": "TB20",
                    "name": "weiteres Zimmer mit Bett, Pullman-Bett"
                },
                {
                    "code": "TD20",
                    "name": "2 Liegestühle mit Beistelltisch"
                },
                {
                    "code": "TD21",
                    "name": "Sonneninsel mit Beistelltisch"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "SUDO",
                "minOccupation": 1,
                "maxOccupation": 6,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "VP",
            "name": "Premium Verandakabine",
            "description": "Auf Ihrer eigenen  großzügigen Veranda können Sie den fantastischen Meerblick in Ihrer persönlichen Hängematte oder Ihrem Liegestuhl genießen.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": "30",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/premium-verandakabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TD10",
                    "name": "Hängematte"
                },
                {
                    "code": "TD11",
                    "name": "Balkon mit Tisch und 2 Stühlen"
                },
                {
                    "code": "TD17",
                    "name": "2 Liegestühle"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "VEPO",
                "minOccupation": 1,
                "maxOccupation": 3,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "BK",
            "name": "Kombi Balkonkabine",
            "description": "Erleben Sie Ihre Reise mit Ferienhauscharakter - Genießen Sie privat mit Freunden oder Familie die Abende auf dem eigenen Balkon.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": "9",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/kombi-balkonkabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TD13",
                    "name": "Balkon mit Tisch und 4 Stühlen"
                },
                {
                    "code": "TD20",
                    "name": "2 Liegestühle mit Beistelltisch"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "BAKO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "BA",
            "name": "Balkonkabine",
            "description": "Beginnen Sie den Tag mit einem Kaffee aus Ihrer Espresso-Maschine auf dem eigenen Balkon und genießen Sie die Aussicht.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": "5-7",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/balkonkabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TB07",
                    "name": "barrierefreie Kabinen auf Anfrage"
                },
                {
                    "code": "TD11",
                    "name": "Balkon mit Tisch und 2 Stühlen"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "BAAW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BABW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BACW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BADW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAEW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAFW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAGW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAHW",
                "minOccupation": 1,
                "maxOccupation": 3,
                "tariff": null
            },
            {
                "code": "BAPS",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAUS",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAUR",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAAO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BABO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BACO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BADO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAEO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAFO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAGO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "BAHO",
                "minOccupation": 1,
                "maxOccupation": 3,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "BS",
            "name": "SPA Balkonkabine",
            "description": "Auf Reisen mit mind. 5 Nächten ist unser Wohlfühl-Paket inklusive und beinhaltet eine Wellnessanwendung, einen Fitness-Workshop & -test, ein Ernährungscoaching sowie 60 Minuten in einer Entspannungsloge.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": "5",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/spa-balkonkabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TB11",
                    "name": "besondere SPA Pflegeprodukte"
                },
                {
                    "code": "TB12",
                    "name": "1 Strandtasche und 1 Schlüsselband p.P."
                },
                {
                    "code": "TB13",
                    "name": "inkl. Wohlfühl-Paket (ab 5 Nächte)"
                },
                {
                    "code": "TD11",
                    "name": "Balkon mit Tisch und 2 Stühlen"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "BASO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "FB",
            "name": "Familienkabine Balkon",
            "description": "Ob Spielekonsole oder Liegestuhl – Unsere Familienkabine Balkon bietet Raum zum Wohlfühlen und Entspannen, ob innen oder außen.",
            "interiorSizeInfo": "25",
            "exteriorSizeInfo": "15",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/familienkabine-balkon",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                },
                {
                    "code": "TD13",
                    "name": "Balkon mit Tisch und 4 Stühlen"
                },
                {
                    "code": "TD19",
                    "name": "1 Liegestuhl"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "FAMO",
                "minOccupation": 1,
                "maxOccupation": 5,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "JB",
            "name": "Junior Suite Balkon",
            "description": "Sie verfügt über eine großzügige Wohnfläche mit hellen Farben. Sie haben zudem Zutritt zur X-Lounge und dem X-Sonnendeck.",
            "interiorSizeInfo": "26",
            "exteriorSizeInfo": "8",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/junior-suite-balkon",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                },
                {
                    "code": "TS02",
                    "name": "Zugang zur X-Lounge"
                },
                {
                    "code": "TS05",
                    "name": "Excellence-Service"
                },
                {
                    "code": "TS06",
                    "name": "kostenfreier Internetzugang"
                },
                {
                    "code": "TB07",
                    "name": "barrierefreie Kabinen auf Anfrage"
                },
                {
                    "code": "TS07",
                    "name": "separater Check-In"
                },
                {
                    "code": "TS08",
                    "name": "kostenfreie Getränke in der Minibar"
                },
                {
                    "code": "TD14",
                    "name": "Balkon/Veranda mit Tisch und 2 Stühlen"
                },
                {
                    "code": "TD22",
                    "name": "2 Fußbänke"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "JBAO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "ST",
            "name": "Themen Suite",
            "description": "Wollten Sie schon immer einmal neben dem Kapitän wohnen? Dann sind Sie in den Themen Suiten genau richtig.",
            "interiorSizeInfo": "54",
            "exteriorSizeInfo": "15",
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/themen-suite",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                },
                {
                    "code": "TS02",
                    "name": "Zugang zur X-Lounge"
                },
                {
                    "code": "TS04",
                    "name": "kostenfreier Zeitungsservice"
                },
                {
                    "code": "TS05",
                    "name": "Excellence-Service"
                },
                {
                    "code": "TS06",
                    "name": "kostenfreier Internetzugang"
                },
                {
                    "code": "TS07",
                    "name": "separater Check-In"
                },
                {
                    "code": "TS08",
                    "name": "kostenfreie Getränke in der Minibar"
                },
                {
                    "code": "TD10",
                    "name": "Hängematte"
                },
                {
                    "code": "TD12",
                    "name": "Balkon/Veranda mit Tisch und 4 Stühlen"
                },
                {
                    "code": "TB15",
                    "name": "begehbarer Kleiderschrank"
                },
                {
                    "code": "TB18",
                    "name": "optisch getrennter Wohnraum"
                },
                {
                    "code": "TB19",
                    "name": "Sitz- und Couchecke"
                },
                {
                    "code": "TB21",
                    "name": "Gäste-WC"
                },
                {
                    "code": "TD19",
                    "name": "1 Liegestuhl"
                },
                {
                    "code": "TD22",
                    "name": "2 Fußbänke"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "SUTO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "IN",
            "name": "Innenkabine",
            "description": "Unsere Innenkabinen bieten bis zu 4 Personen viel Platz zum Wohlfühlen. Starten Sie am Morgen mit einem Kaffee aus der Espresso-Maschine.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": null,
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/innenkabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TB07",
                    "name": "barrierefreie Kabinen auf Anfrage"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "INAO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "INBO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "INCO",
                "minOccupation": 1,
                "maxOccupation": 3,
                "tariff": null
            },
            {
                "code": "INAW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "INBW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "INCW",
                "minOccupation": 1,
                "maxOccupation": 3,
                "tariff": null
            },
            {
                "code": "INUS",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "INUR",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "AU",
            "name": "Außenkabine",
            "description": "Ein großes Panoramafenster lädt dazu ein, den Ausblick auf die Weite des Meeres zu genießen.",
            "interiorSizeInfo": "17",
            "exteriorSizeInfo": null,
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/aussenkabine",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TB07",
                    "name": "barrierefreie Kabinen auf Anfrage"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "AUBO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "AUAW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "AUBW",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "AUUS",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "AUUR",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            },
            {
                "code": "AUAO",
                "minOccupation": 1,
                "maxOccupation": 4,
                "tariff": null
            }
        ]
    },
    {
        "cabinType": {
            "code": "FA",
            "name": "Familienkabine Außen",
            "description": "Die Kabine mit einer geräumigen Wohnfläche und großen Fenstern mit Blick zum Schiffsbug bietet Platz für bis zu 6 Personen.",
            "interiorSizeInfo": "29-44",
            "exteriorSizeInfo": null,
            "landingPage": "https://meinschiff.com/mein-schiff-6/kabinen/familienkabine-aussen",
            "equipments": [
                {
                    "code": "TD01",
                    "name": "Bademantel"
                },
                {
                    "code": "TD02",
                    "name": "Espresso-Maschine"
                },
                {
                    "code": "TD03",
                    "name": "Slipper"
                },
                {
                    "code": "TD04",
                    "name": "Klimaanlage"
                },
                {
                    "code": "TD05",
                    "name": "TV"
                },
                {
                    "code": "TD06",
                    "name": "Safe"
                },
                {
                    "code": "TD07",
                    "name": "Telefon"
                },
                {
                    "code": "TD08",
                    "name": "Haartrockner"
                },
                {
                    "code": "TD09",
                    "name": "Bad mit Dusche/WC"
                },
                {
                    "code": "TS01",
                    "name": "Spielekonsole"
                }
            ],
            "images": []
        },
        "cabinCategories": [
            {
                "code": "FAAO",
                "minOccupation": 1,
                "maxOccupation": 6,
                "tariff": null
            },
            {
                "code": "FABO",
                "minOccupation": 1,
                "maxOccupation": 6,
                "tariff": null
            }
        ]
    }
]
OLD:
[
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6212/77650162170593/ms11805_kabinen_rs_08.jpg",
                "description": "Ansicht einer Innenkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/62499504429640/ms11805_kabinen_rs_27.jpg",
                "description": "Anischt einer Innenkabine mit Babybett auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/99688419164604/ms11805_kabinen_rs_14.jpg",
                "description": "Ansicht einer Innenkabine mit Schlafcouch auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/67486354471494/ms11805_kabinen_rs_16.jpg",
                "description": "Ansicht einer barrierefreien Innenkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/18802831329888/ms11805_kabinen_rs_15.jpg",
                "description": "Ansicht einer barrierefreien Innenkabine mit Schlafcouch auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/46215293739652/ms11805_kabinen_badezimmer_rs_01.jpg",
                "description": "Kabinen Badezimmer Barrierefrei auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC"
        ],
        "exteriorType": null,
        "interiorSizeInfo": "17",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "barrierefreie Kabinen auf Anfrage"
        ],
        "description": "Unsere Innenkabinen bieten bis zu 4 Personen viel Platz zum Wohlfühlen. Starten Sie am Morgen mit einem Kaffee aus der Espresso-Maschine.",
        "categoryTypeCode": "IN",
        "exteriorSizeInfo": null,
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/12875031617852/innenkabine.jpg"
        ],
        "cabinTypeAdditionalInterior": null,
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/55745989608163/panoramaimage_eb95200e0f9e0a48ccbd5b94fc2dfcc3.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5794"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/47505035480244/panoramaimage_70b65cf9ff4afad8ed86a2c312e1d62e.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0267"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/80777216802293/panoramaimage_dcd5035cbd738566ec861870bb9537b5.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0302"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/71354832117556/panoramaimage_0028082c1839aff03360dc8adb7a3dad.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0197"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/14275645109968/panoramaimage_17e543b5b1e26693942e41df404fdcbc.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6055"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/93819872485385/panoramaimage_ebfce7190015cd26dfc5d24b19372c8a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0624"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/65887633285977/panoramaimage_042746cb0a50c80cdc84cb0bcd503561.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0872"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/67529901838627/panoramaimage_2c69ec7bf9bad2009e12357924aaaa48.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5894"
            }
        ],
        "otdsCabinTypeName": "Interior",
        "cabinTypeName": "Innenkabine",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6212/45322345065258/ms11805_kabinen_rs_06.jpg",
                "description": "Ansicht einer Außenkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/84158198989120/ms11805_kabinen_rs_10.jpg",
                "description": "Ansicht einer Außenkabine mit Schlafcouch auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/01192803613638/ms11805_kabinen_rs_09.jpg",
                "description": "Ansicht einer Außenkabine mit Babybett auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/17262631783494/ms11805_kabinen_rs_18.jpg",
                "description": "Ansicht einer barrierefreien Außenkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6212/13274464746872/ms11805_kabinen_rs_07.jpg",
                "description": "Ansicht einer barrierefreien Außenkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/46215293739652/ms11805_kabinen_badezimmer_rs_01.jpg",
                "description": "Kabinen Badezimmer Barrierefrei auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC"
        ],
        "exteriorType": null,
        "interiorSizeInfo": "17",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "barrierefreie Kabinen auf Anfrage"
        ],
        "description": "Ein großes Panoramafenster lädt dazu ein, den Ausblick auf die Weite des Meeres zu genießen.",
        "categoryTypeCode": "AU",
        "exteriorSizeInfo": null,
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/58911732551424/aussenkabine.jpg"
        ],
        "cabinTypeAdditionalInterior": null,
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/22073070150638/panoramaimage_d388f7277eb1cfaf9439c65c4fdc8781.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0706"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/42748474474437/panoramaimage_348d27d23ae66d2234fd51cb0d5c4ea8.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5740"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/85323529052096/panoramaimage_04409656bfc136e9eb2086b99261ca25.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7025"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/55703774969910/panoramaimage_6e3b12edb4ee19420c8c04fc2c673f67.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0823"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/17167867517001/panoramaimage_75acdca9ac115c4dfcc8cb6ad5ec034c.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0526"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/71450445769963/panoramaimage_b688be24bdce53a52468f019d73d244b.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5593"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/24481926626146/panoramaimage_595afd23b64923ca2e7608a45dc19d77.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0365"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/88470844504791/panoramaimage_7ad4e613c801bf6cc0a93b2f545ffe33.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0767"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6930/27484718467363/panoramaimage_91f4b4eea0fd6e47b52792a40f16dc1a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5906"
            }
        ],
        "otdsCabinTypeName": "Outside",
        "cabinTypeName": "Außenkabine",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/30507283679363/ms11805_kabinen_rs_28.jpg",
                "description": "Ansicht einer Balkonkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/66037968887640/ms11805_kabinen_rs_21.jpg",
                "description": "Ansicht einer Balkonkabine mit Babybett auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/82151029651449/ms11805_kabinen_rs_22.jpg",
                "description": "Balkonsicht auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/50627538322480/ms11805_kabinen_rs_29.jpg",
                "description": "Ansicht einer barrierefreien Balkonkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/46215293739652/ms11805_kabinen_badezimmer_rs_01.jpg",
                "description": "Kabinen Badezimmer Barrierefrei auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Balkon mit Tisch und 2 Stühlen"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "17",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "barrierefreie Kabinen auf Anfrage"
        ],
        "description": "Beginnen Sie den Tag mit einem Kaffee aus Ihrer Espresso-Maschine auf dem eigenen Balkon und genießen Sie die Aussicht.",
        "categoryTypeCode": "BA",
        "exteriorSizeInfo": "5-7",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/7331/66980817422394/grundriss_balkonkabine_meinschiff1-2.png",
            "https://test.meinschiff.com/media/7331/86351780039660/grundriss_balkonkabine_barrierefrei_meinschiff1-2.png"
        ],
        "cabinTypeAdditionalInterior": null,
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/24590303778588/panoramaimage_e933708ce949df9c095b18885223f7aa.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3287"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/49395974900291/panoramaimage_d31a0139a56acfe68ce5c055eea0a4bc.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7821"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/31880688196241/panoramaimage_b0125a9a6b25d9f6d5d3d852a73f64e4.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7612"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/45675847545277/panoramaimage_4fd88e147eb2d4fb5deb19655484caf3.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7660"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/65989453721859/panoramaimage_ccb0839a8f2fb33345c40f3ba20008d1.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:2809"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/98504320202292/panoramaimage_256aac250f8430d1c52e454a44759d40.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6048"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/14954294621970/panoramaimage_f65b3344644fc46cee4428b7db31d241.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3013"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/04789220163495/panoramaimage_aa4c5654b84e5b59ce894b1c0105f468.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:8574"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/06121121782660/panoramaimage_62988565ee7ae058fd36b480d856d0c5.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:8188"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/15930171164454/panoramaimage_871be269c2939f95c71767c0d5f11bc1.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5888"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/92860022840499/panoramaimage_e8910f545d69599a522e6e46de98a4ac.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3068"
            }
        ],
        "otdsCabinTypeName": "Balcony",
        "cabinTypeName": "Balkonkabine",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/30507283679363/ms11805_kabinen_rs_28.jpg",
                "description": "Ansicht einer Balkonkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/18890391162329/ms11805_kabinen_rs_24.jpg",
                "description": "Balkonaussicht auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Balkon mit Tisch und 2 Stühlen"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "17",
        "maxOccupation": "3",
        "cabinTypeSpecifics": [
            "besondere SPA Pflegeprodukte",
            "1 Strandtasche und 1 Schlüsselband p.P.",
            "inkl. Wohlfühl-Paket (ab 5 Nächte)"
        ],
        "description": "Auf Reisen mit mind. 5 Nächten ist unser Wohlfühl-Paket inklusive und beinhaltet eine Wellnessanwendung, einen Fitness-Workshop & -test, ein Ernährungscoaching sowie 60 Minuten in einer Entspannungsloge.",
        "categoryTypeCode": "BS",
        "exteriorSizeInfo": "5",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/7331/66980817422394/grundriss_balkonkabine_meinschiff1-2.png"
        ],
        "cabinTypeAdditionalInterior": null,
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/24590303778588/panoramaimage_e933708ce949df9c095b18885223f7aa.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3287"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/49395974900291/panoramaimage_d31a0139a56acfe68ce5c055eea0a4bc.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7821"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/65989453721859/panoramaimage_ccb0839a8f2fb33345c40f3ba20008d1.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:2809"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/98504320202292/panoramaimage_256aac250f8430d1c52e454a44759d40.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6048"
            }
        ],
        "otdsCabinTypeName": "Balcony",
        "cabinTypeName": "SPA Balkonkabine",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/11152237690207/ms11805_kabinen_rs_25.jpg",
                "description": "Blick auf den Außenbereich der Verandakabine auf dem Kreuzfahrtschiff Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/30507283679363/ms11805_kabinen_rs_28.jpg",
                "description": "Ansicht einer Balkonkabine auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/66037968887640/ms11805_kabinen_rs_21.jpg",
                "description": "Ansicht einer Balkonkabine mit Babybett auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon/Veranda mit Tisch und 2 Stühlen",
            "2 Liegestühle"
        ],
        "exteriorType": "Veranda",
        "interiorSizeInfo": "17",
        "maxOccupation": "3",
        "cabinTypeSpecifics": null,
        "description": "Auf Ihrer eigenen großzügigen Veranda können Sie den fantastischen Meerblick in Ihrer persönlichen Hängematte oder Ihrem Liegestuhl genießen.",
        "categoryTypeCode": "VP",
        "exteriorSizeInfo": "30",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/28074972465651/ms_1_premium_verandakabine.jpg"
        ],
        "cabinTypeAdditionalInterior": null,
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/58578102934722/panoramaimage_f39cce56c7f22ca1c1fd5e054f4e1ca5.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:2845"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/38409905468345/panoramaimage_9d2f4f1886868a5ca6d79b26907b1161.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:2894"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/59181774672603/panoramaimage_749762100d5b7ccce789cfceaf76367a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5594"
            }
        ],
        "otdsCabinTypeName": "Veranda",
        "cabinTypeName": "Premium Verandakabine",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/5878/05895067110619/ms11805_kabinen_rs_05.jpg",
                "description": "Familienkabine Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/80807125201301/ms11805_kabinen_rs_03.jpg",
                "description": "Familienkabine Außen ohne Babybett auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/64916978218511/ms11805_kabinen_rs_01.jpg",
                "description": "Familienkabine Außen auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/86395332346379/ms11805_kabinen_rs_04.jpg",
                "description": "Familienkabine Verande Deluxe mit Doppelstockbett auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/15339888232491/ms11805_kabinen_rs_02.jpg",
                "description": "Familienkabine Außen mit Babybett auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC"
        ],
        "exteriorType": null,
        "interiorSizeInfo": "29-44",
        "maxOccupation": "6",
        "cabinTypeSpecifics": null,
        "description": "Die Kabine mit einer geräumigen Wohnfläche und großen Fenstern mit Blick zum Schiffsbug bietet Platz für bis zu 6 Personen.",
        "categoryTypeCode": "FA",
        "exteriorSizeInfo": null,
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/30082347981196/familienkabine_aussen.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/42372346609612/panoramaimage_fe4321dc221797ea156c43939c5b9b7e.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6170"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/12355846540197/panoramaimage_052e2e15cc2323ec3ca8fbbe9b314fac.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6114"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/96103472854966/panoramaimage_f814d4637c6cb9009611f20c28aadda0.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6072"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/80986971570398/panoramaimage_9fe2e080b7b5c2f1ce389f493729c7fa.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0001"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/31641918814433/panoramaimage_8e1f0176a8cdcfa0e8355d5953ff7fa4.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:6324"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/03657333359830/panoramaimage_10723def412678b87cee90b8c4fb8e70.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0050"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/38637283670279/panoramaimage_749762100d5b7ccce789cfceaf76367a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5602"
            }
        ],
        "otdsCabinTypeName": "Family",
        "cabinTypeName": "Familienkabine Außen",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/72264623956375/ms11805_kabinen_rs_20.jpg",
                "description": "Ansicht einer Familienkabine Balkon auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/75376313549088/ms11805_kabinen_rs_19.jpg",
                "description": "Ansicht einer Familienkabine Balkon mit Babybett auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/37338261644283/ms11805_kabinen_rs_23.jpg",
                "description": "Balkonsicht auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5878/45979089792135/ms11805_kabinen_badezimmer_rs_02.jpg",
                "description": "Kabinen Badezimmer auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Balkon/Veranda mit Tisch und 4 Stühlen",
            "1 Liegestuhl"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "25",
        "maxOccupation": "5",
        "cabinTypeSpecifics": null,
        "description": "Ob Spielekonsole oder Liegestuhl – Unsere Familienkabine Balkon bietet Raum zum Wohlfühlen und Entspannen, ob innen oder außen.",
        "categoryTypeCode": "FB",
        "exteriorSizeInfo": "15",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/93562814420932/familienkabine_balkon.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/53583693171479/panoramaimage_a2a13f89f77d121727c899c0364834aa.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7408"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/07581862337071/panoramaimage_dbad31b948582598d212f1c432840b25.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:8300"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/79074529325243/panoramaimage_211279bef103080e4cb180228f9ee9aa.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7310"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/13958836723262/panoramaimage_c793f5c3241be0c57a56f2c4a2c6589f.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7205"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/97750380451540/panoramaimage_6b4f54b75cccd8bd07e39ce71f2b33d2.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7156"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/52226210827683/panoramaimage_97f03d21a6b80f9d0336fe62eb4bbf07.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7506"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/44186233530818/panoramaimage_749762100d5b7ccce789cfceaf76367a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5599"
            }
        ],
        "otdsCabinTypeName": "Family",
        "cabinTypeName": "Familienkabine Balkon",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/5879/29428766842046/ms11805_suiten_rs_01.jpg",
                "description": "Junior Suite der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/86441850038297/ms11805_suiten_badezimmer_rs_01.jpg",
                "description": "Badezimmer der Junior Suite auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/30974408199834/ms11805_suiten_rs_02.jpg",
                "description": "Barrierefreie Junior Suite der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/18475781548803/ms11805_suiten_badezimmer_rs_02.jpg",
                "description": "Barrierefreies Badezimmer der Junior Suite auf der neuen Mein Schiff 1 "
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Balkon mit Tisch und 2 Stühlen",
            "2 Fußbänke"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "26",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "barrierefreie Kabinen auf Anfrage"
        ],
        "description": "Unsere Junior Suite verfügt über eine großzügige Wohnfläche mit hellen Farben. Sie haben zudem Zutritt zur X-Lounge und dem X-Sonnendeck.",
        "categoryTypeCode": "JB",
        "exteriorSizeInfo": "7",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/96602004209191/junior_suite_balkon.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/45008404884785/panoramaimage_378e1e48673669e5b44ce4f083f0ef04.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3196"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/21868056639913/panoramaimage_313c9f0e52dcda3bdce68a58ef21adfc.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:8525"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/78780823649900/panoramaimage_bf06bc1fbde56fad4a5f84f9792172d3.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3252"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/06045910903276/panoramaimage_f9b67aad6c10a70b6286921eb1d7ad93.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:7929"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/31793646979129/panoramaimage_d92c905d689cf0c73ee6a6171d4ae2c5.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3376"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/67810900339677/panoramaimage_e3da8f3426a083e561cd580a52fce221.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:8090"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/05169734480210/panoramaimage_352eb791198ea5ca81305b2bafadb1b4.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:3425"
            }
        ],
        "otdsCabinTypeName": "JuniorSuite",
        "cabinTypeName": "Junior Suite Balkon",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/5879/75840279072352/ms11805_suiten_rs_04.jpg",
                "description": "Himmel & Meer Suite der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/10940890381113/ms11805_suiten_badezimmer_rs_03.jpg",
                "description": "Badezimmer der Übersee sowie Himmel & Meer Suite auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon/Veranda mit Tisch und 4 Stühlen",
            "2 Liegestühle"
        ],
        "exteriorType": "Terrasse",
        "interiorSizeInfo": "42",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "Dachterrasse teilweise mit Sonnenschutz",
            "1 Korbsessel"
        ],
        "description": "Die Dachterrasse der Himmel & Meer Suite lädt zum privaten Sonnenbad oder zum abendlichen Betrachten der Sterne ein.",
        "categoryTypeCode": "SM",
        "exteriorSizeInfo": "29",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/08612063115272/himmel_meer_suite_ms1.jpg",
            "https://test.meinschiff.com/media/6939/64892256121004/himmelmeer_suite_dachterasse.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "kostenfreier Zeitungsservice",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/91418311906033/panoramaimage_6bb8c9848a4ed129f3306e21f9a6e844.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0928"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/10735381864934/panoramaimage_19726c238c8ac7f7867b067e1197ccd7.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:0977"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/29432444360409/panoramaimage_046de02eb11b00b59219484bd723a210.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4545"
            }
        ],
        "otdsCabinTypeName": "Suite",
        "cabinTypeName": "Himmel & Meer Suite",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/57971663976225/ms11805_suiten_rs_13.jpg",
                "description": "Ansicht der Schöne Aussicht Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/14517827824136/ms11805_suiten_rs_12.jpg",
                "description": "Ansicht einer Schöne Aussicht Suite auf der Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon mit Tisch und 2 Stühlen",
            "2 Liegestühle"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "39",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "Doppeldusche"
        ],
        "description": "In dieser Suite erwartet Sie eine schöne Aussicht auf den Diamanten und das Meer. Genießen Sie am Abend ein Getränk aus der inkludierten Minibar auf Ihrem Balkon.",
        "categoryTypeCode": "SA",
        "exteriorSizeInfo": "13-14",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/50258536849317/schone_aussicht_suite.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "kostenfreier Zeitungsservice",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/87287361725489/panoramaimage_1613c7ebf019cca206ac05659a526261.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:1073"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/06296828782496/panoramaimage_866088e6f586bd0e8fcbeedaa42ed7e9.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:1167"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/73581293937999/panoramaimage_fa35f3f5089c9bb85f6d277e1c3dcc1a.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:1018"
            }
        ],
        "otdsCabinTypeName": "Suite",
        "cabinTypeName": "Schöne Aussicht Suite",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/5879/96739525302588/ms11805_suiten_rs_07.jpg",
                "description": "Horizont Suite auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/93186475839834/ms11805_suiten_rs_18.jpg",
                "description": "Ansicht der Horizont Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/37855950389919/ms11805_suiten_badezimmer_rs_08.jpg",
                "description": "Badezimmer der Horizont Suite auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon mit Tisch und 2 Stühlen",
            "2 Liegestühle"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "38-40",
        "maxOccupation": "4",
        "cabinTypeSpecifics": [
            "Doppeldusche",
            "optisch getrennter Wohnraum",
            "Sitz- und Couchecke"
        ],
        "description": "Genießen Sie den Ausblick am Heck des Schiffes durch eine eindrucksvolle Fensterfront oder lassen Sie auf der geräumigen Veranda einfach die Seele baumeln.",
        "categoryTypeCode": "SH",
        "exteriorSizeInfo": "16",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/48157363462204/horizont_suite.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "kostenfreier Zeitungsservice",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/74366213391433/panoramaimage_232fdd9864bf0dc72faea2835c53e1f8.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4750"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/58566995559016/panoramaimage_7c4ceb2e29ea5ecafc652952d689854d.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4799"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/38294423453589/panoramaimage_09c7840f7f2368a22ccb7912c4f2ef3f.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4848"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/60771037654353/panoramaimage_0c657fc51fccae8517ff01c9d21e4f8f.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4923"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/99810286439373/panoramaimage_7eb2e2f339c7400148594f7789a5babe.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4972"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/12648290405192/panoramaimage_b4b9b8cdf04dedcbd2d213eeff3e4ea1.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5021"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/87656750278839/panoramaimage_5478d1be4b6696cbe8819be8064bb686.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4708"
            }
        ],
        "otdsCabinTypeName": "Suite",
        "cabinTypeName": "Horizont Suite",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/5879/49316743573563/ms11805_suiten_rs_03.jpg",
                "description": "Übersee Suite der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/35719521444080/ms11805_suiten_rs_11.jpg",
                "description": "Ansicht einer Übersee Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/96853244793772/ms11805_suiten_badezimmer_rs_04.jpg",
                "description": "Badezimmer der Übersee sowie Himmel & Meer Suite auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon mit Tisch und 2 Stühlen",
            "1 Liegestuhl"
        ],
        "exteriorType": "Balkon",
        "interiorSizeInfo": "40",
        "maxOccupation": "4",
        "cabinTypeSpecifics": null,
        "description": "Viel Komfort und ein toller Ausblick vom Balkon erwartet sie hoch oben auf dem Schiff in unserer Übersee Suite.",
        "categoryTypeCode": "SU",
        "exteriorSizeInfo": "9",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/29047405888772/ubersee_suite.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "kostenfreier Zeitungsservice",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/37481976200040/panoramaimage_7d0df505e3b65b4bdd2874ff34b7b623.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4614"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/48510975421521/panoramaimage_bab965b5c258f3060afd4a9e012095a8.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4663"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/05309185505442/panoramaimage_3a0e3b59aa89429855fcffcbad422938.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:4544"
            }
        ],
        "otdsCabinTypeName": "Suite",
        "cabinTypeName": "Übersee Suite",
        "minOccupation": "1"
    },
    {
        "images": [
            {
                "imageURL": "https://test.meinschiff.com/media/6213/73542473316982/ms11805_suiten_rs_14.jpg",
                "description": "Ansicht der Panorama Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/72343963490932/ms11805_suiten_rs_15.jpg",
                "description": "Ansicht der Panorama Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/83116915327673/ms11805_suiten_rs_16.jpg",
                "description": "Ansicht der Panorama Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/01095181110600/ms11805_suiten_rs_05.jpg",
                "description": "Schöne Aussicht Suite der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/36890286349047/ms11805_suiten_rs_06.jpg",
                "description": "Korridor der Panorama Suite auf der neuen Mein Schiff 1 "
            },
            {
                "imageURL": "https://test.meinschiff.com/media/6213/28612473662804/ms11805_suiten_rs_17.jpg",
                "description": "Ansicht auf den Balkon der Panorama Suite auf der Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/07353080067990/ms11805_suiten_badezimmer_rs_06.jpg",
                "description": "Badezimmer der Panorama Suite auf der neuen Mein Schiff 1"
            },
            {
                "imageURL": "https://test.meinschiff.com/media/5879/75667525004263/ms11805_suiten_badezimmer_rs_05.jpg",
                "description": "Badezimmer der Panorama Suite auf der neuen Mein Schiff 1"
            }
        ],
        "cabinTypeInterior": [
            "Bademantel",
            "Espresso-Maschine",
            "Slipper",
            "Klimaanlage",
            "TV",
            "Safe",
            "Telefon",
            "Haartrockner",
            "Bad mit Dusche/WC",
            "Hängematte",
            "Balkon/Veranda mit Tisch und 2 Stühlen",
            "2 Liegestühle"
        ],
        "exteriorType": "Veranda",
        "interiorSizeInfo": "73-77",
        "maxOccupation": "6",
        "cabinTypeSpecifics": [
            "Sitzecke",
            "Doppeldusche",
            "optisch getrennter Wohnraum",
            "Sitz- und Couchecke",
            "zusätzliches Schlafzimmer mit 2 Betten",
            "zusätzliches Badezimmer mit Dusche/WC"
        ],
        "description": "Genießen Sie die eindrucksvolle Aussicht auf den Diamanten von Ihrer großzügigen Veranda und profitieren Sie von einem zusätzlichen Schlaf- und Badezimmer.",
        "categoryTypeCode": "SP",
        "exteriorSizeInfo": "31-36",
        "floorplanImageURLs": [
            "https://test.meinschiff.com/media/106/22300865401099/panorama_suite.jpg"
        ],
        "cabinTypeAdditionalInterior": [
            "Spielekonsole",
            "Zugang zur X-Lounge",
            "kostenfreier Zeitungsservice",
            "Excellence-Service",
            "kostenfreier Internetzugang",
            "separater Check-In",
            "kostenfreie Getränke in der Minibar"
        ],
        "panoramas": [
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/48740403447574/panoramaimage_8b684aeb5d5672c614190139a03e15ee.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5139"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/47664965796432/panoramaimage_241da2b738e904bd172b0e40ae962cf6.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5189"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/79341116524925/panoramaimage_c908ad14833dd59344fef66f79006020.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5240"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/52571767226159/panoramaimage_4e00cdca303f091c5df8f4d4fe0c8697.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5295"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/40070584634292/panoramaimage_bd4a28e5c601e388d31b0ceab16ee76c.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5330"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/87076930784267/panoramaimage_18801e8c0e2e45ed12c5be905c1d6b00.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5096"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6928/37511985905917/panoramaimage_0b60f03e8bb3bc5d6d8a2d2c0688d75f.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5061"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/11381133824476/panoramaimage_f7ab36dc3bd0220d403368ce33ab16dc.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5442"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/19182097215672/panoramaimage_742edc0ad0da76d5eed09fc16119efc9.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5491"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/43354251636427/panoramaimage_16c5a79a26bdaf15c38bd98a1c0866ff.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5568"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/97050631832862/panoramaimage_d868c0d89ffe51348c763b71799ef5e6.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5622"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/24899474187062/panoramaimage_db475b8652308380b77b39612bad5e7e.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5400"
            },
            {
                "previewImageURL": "https://test.meinschiff.com/media/6929/17918178581450/panoramaimage_f239a60d8236133102451bd7126c048e.jpg",
                "panoramaURL": "https://panorama.meinschiff.com/_/index.php?options=projectPath:../de_00247,file:5365"
            }
        ],
        "otdsCabinTypeName": "Suite",
        "cabinTypeName": "Panorama Suite",
        "minOccupation": "1"
    }
]
*/

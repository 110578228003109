import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import app from '../../app/app';
import * as Constants from '../../config/constants';
import {CabinPrice} from '../cabin/cabin-price';
import SVGFile from '../cabin/cabin-svg';

type CabinCategoryProps = {
    detail: any;
    stepModelCabinCategory: any;
};
type BookingStatusType = {
    trip: {
        get: Function;
    } | any
} | null;

export default function CategoryView (props: CabinCategoryProps) {
    const {
        detail,
        stepModelCabinCategory
    } = props;

    const bookingStatus: BookingStatusType = app?.apiSession?.bookingStatus || null;
    const shipCode = bookingStatus?.trip.get('shipCode') || null;
    const thisInput = stepModelCabinCategory.inputs ? stepModelCabinCategory.inputs.get(Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE) : null;
    const thisInputValue = thisInput ? thisInput.get('inputValue') : null;

    const [open, setOpen] = useState(!thisInputValue);

    const content = app.contentApi;
    const cabinCategoryText: any = content ? content.messages.prepareMessages('cabinCategory') : null;
    return (
        <div className="cabin-catergory">
            <h2 className="api-step-headline">
                <FormattedMessage id="components.cabinnumber.category.headline"/>
            </h2>
            <div className="part-cc selected">
                <div className="category">
                    <span className="bold">{detail ? detail.displayText : ''}</span>
                    <span className={`arrow ${open ? 'open' : ''}`} onClick={() => {
                        setOpen(!open);
                    }}></span>
                </div>
                <div className="image">
                    <SVGFile highlight={detail ? detail.id : ''} shipCode={shipCode}></SVGFile>
                </div>
                <div className={`inner-cc ${open ? 'show' : 'hidden-anim'}`}>
                    <div className="notifications">
                        {detail && detail.content && detail.content.decks && detail.content.decks.length !== 0 ?
                            <div className="deck">
                                <div className="bold">{cabinCategoryText ? cabinCategoryText.decksPerCategory : ''}</div>
                                {detail.content.decks.map((deck: any, deckKey: number) => {
                                    return (
                                        <div key={deckKey + '' + deck.number} className="deck-list">
                                            <FormattedMessage id="general.cabin.location" values={{
                                                cabinType: detail.cabinTypeName,
                                                number: deck.number,
                                                deck: deck.name
                                            }}/>
                                        </div>);
                                })}
                            </div>
                            : ''}
                        {detail && detail.content && detail.content.characteristics && detail.content.characteristics.length !== 0 ?
                            <div className="special">
                                <div className="bold">
                                    <FormattedMessage id="general.specials"/>
                                </div>
                                {detail.content.characteristics.map((eq: any, eqkKey: number) => {
                                    return (
                                        <div key={eqkKey} className="eq-list">
                                            <span className="text">{eq.value}</span>
                                        </div>);
                                })}
                            </div>
                            : ''}
                    </div>
                    {detail && detail.price ?
                        <div className="cabin-price-cta-wrapper ">
                            <span className="nobr">
                                <CabinPrice price={detail.price} mode="from_pp"/>
                            </span>
                            <span className="cabin-selected">
                                <FormattedMessage id="general.selected"/>
                            </span>
                        </div>
                        : ''}
                </div>
            </div>
        </div>
    );
}

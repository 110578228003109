import React, {useEffect, useState} from 'react';
import LegalOverviewTextView from './legal';
import B2BOverviewTextView from '../sections/b2b/overview-text';


import app from '../../app/app';

import * as Constants from '../../config/constants';
import {NETMATCH_STEP_APPROVAL_PRELIMINARY_BOOKING_OVERVIEW} from '../../config/constants';
import ibeApiConfig from '../../config/ibe-api';
import {downloadFile} from '../../util/downloadFile';
import {getIntl} from '../../intl';

/**
 * approval checkbox Overview
 */
export default function ApprovalOverviewView () {
    const contentAPITexte: any = app?.contentApi?.messages.prepareMessages('personalData');
    const isB2B: boolean = app?.apiSession?.bookingStatus?.agency.isB2B() !== false;
    const {formatMessage} = getIntl();

    const steps: any = (app.apiSession && app.apiSession.steps) ? app.apiSession.steps : null;
    const approval = (steps) ? steps.get(Constants.NETMATCH_STEP_APPROVAL) : {};
    const approvalTerms = (approval) ? approval.inputs.get(Constants.NETMATCH_INPUT_APPROVAL_OF_TERMS) : {};
    const approvalNewsLetter = (approval) ? approval.inputs.get(Constants.NETMATCH_INPUT_SUBSCRIBE_TO_NEWSLETTER) : {};

    const disabled = approval.isBlocked() || approval.isNotApplicable();

    approvalTerms.setInputValue(false);

    useEffect(() => {
        if (approval?.attributes?.netMatchAttributes?.length) {
            const prelimEntry = approval.attributes.netMatchAttributes.find((entry: { key: string, url: string }) => entry.key === NETMATCH_STEP_APPROVAL_PRELIMINARY_BOOKING_OVERVIEW);
            if (prelimEntry) {
                const interval = setInterval(() => {
                    const vviDownloadLink = document.querySelector<HTMLAnchorElement>('#vvi-download-link');
                    if (vviDownloadLink) {
                        const url = `${window.location.origin}${ibeApiConfig.rootURL}${prelimEntry.url.replace('~', '')}`;
                        vviDownloadLink.href = '';
                        vviDownloadLink.addEventListener('click', (evt) => {
                            // @ts-ignore Backbone Event
                            app.trigger('loading:start');
                            downloadFile(url, formatMessage({id: 'vvi.download.file-name'})).then(() => {
                                // @ts-ignore Backbone Event
                                app.trigger('loading:stop');
                            }).catch(() => {
                                // @ts-ignore Backbone Event
                                app.trigger('loading:stop');
                            });
                            evt.preventDefault();
                            return false;
                        });
                        clearInterval(interval);
                    }
                }, 100);
            }
        }
    }, [
        formatMessage,
        approval
    ]);

    return (
        <>
            <LegalOverviewTextView></LegalOverviewTextView>
            <div className="api-step-approval">
                <CheckboxElement part={approvalTerms} disabled={disabled} default={false}/>
                <CheckboxElement part={approvalNewsLetter} disabled={disabled}/>
                <div className="ssl-hint" dangerouslySetInnerHTML={{__html: contentAPITexte.privacy}}></div>
            </div>
            {isB2B ? <B2BOverviewTextView></B2BOverviewTextView> : ''}
        </>
    );
}

type CheckboxElementProps = {
    part: any;
    disabled: boolean;
    default?: boolean;

};
const CheckboxElement = (props: CheckboxElementProps) => {
    const {
        part,
        disabled
    } = props;
    const partPain = part.toJSON();
    const [select, setSelect] = useState({
        value: props.default ? props.default : partPain.inputValue,
        hasError: false,
        error: ''
    });

    const changeValue = (value: boolean) => {
        const {
            hasError,
            details
        } = checkInput(partPain.validators, value);

        if (hasError === true) {
            setSelect({
                value: value,
                hasError: true,
                error: details
            });
            part.setInputValue(false);
        } else {
            setSelect({
                value: value,
                hasError: false,
                error: ''
            });
            part.setInputValue(value);
            // callback(value);
        }
        // @ts-ignore Backbone Event
        app.trigger('react:change:inputValue:no:autosubmit');

        const translate: any = {
            [Constants.NETMATCH_INPUT_APPROVAL_OF_TERMS]: 'AGB',
            [Constants.NETMATCH_INPUT_SUBSCRIBE_TO_NEWSLETTER]: 'Newsletter'
        };
        if (app?.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: `${translate[part.id]}`,
                ga4clickLabel: `content.button.uebersicht-${translate[part.id]}`
            });
        }
    };

    return (
        <div className={`form-group${select.hasError ? ' has-errors' : ''}`}>
            <div className="checkbox-element ">
                <input type="checkbox" id={part.id} disabled={disabled} checked={select.value}
                    onChange={() => {
                        changeValue(!select.value);
                    }}/>
                <label htmlFor={part.id} dangerouslySetInnerHTML={{__html: partPain.label}}/>
            </div>
            <div className="form-error">{select.error}</div>
        </div>
    );
};


type ValidatorType = {
    type: string;
    warning: string;
    matchValue?: any;
}
const checkInput = (validators: ValidatorType[], value: boolean) => {
    let hasError = false;
    let details = '';
    validators.every((item) => {
        if (item.type === Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED && value !== true) {
            hasError = true;
            details = item.warning;
        }
        if (hasError === false && item.type === Constants.NETMATCH_VALIDATOR_TYPE_EQUALS) {
            if (item.matchValue !== value) {
                hasError = true;
                details = item.warning;
            }
        }
        return true;
    });

    return {
        hasError,
        details
    };
};

export async function downloadFile (url: string, filename: string): Promise<void> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();

        const urlObject = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlObject;
        a.download = filename;
        document.body.appendChild(a); // Required for Firefox
        a.click();

        // Clean up the temporary URL and remove the anchor
        URL.revokeObjectURL(urlObject);
        document.body.removeChild(a);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}

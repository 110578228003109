import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import app from '../../app/app';
import * as Constants from '../../config/constants';

type DeckNumberProps = {
    stepModelCabinDeck: any;
    stepModelCabinSelection: any;
    callback: Function;
};

type StepDataType = { id: string, content: {} };
export default function DeckNumberView (props: DeckNumberProps) {
    const {formatMessage} = useIntl();
    const {
        stepModelCabinDeck,
        stepModelCabinSelection,
        callback
    } = props;


    return (
        <div className="decknumber">
            <h2 className="api-step-headline">
                <FormattedMessage id="components.cabinnumber.deck-number.headline"/>
            </h2>
            <div className="row">
                <div className="col-xs-6">
                    <InputSelectElement text={formatMessage({
                        id: 'components.cabinnumber.deck-number.deck'
                    })} label={formatMessage({
                        id: 'components.cabinnumber.deck-number.availableDecks'
                    })} stepModel={stepModelCabinDeck} type={Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER} callback={callback}></InputSelectElement>
                </div>
                <div className="col-xs-6">
                    <InputSelectElement text={formatMessage({
                        id: 'components.cabinnumber.deck-number.cabin'
                    })} label={formatMessage({
                        id: 'components.cabinnumber.deck-number.availableCabins'
                    })} stepModel={stepModelCabinSelection} type={Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER} callback={callback}></InputSelectElement>
                </div>
            </div>
        </div>
    );
}

export interface InputSelectProps {
    text: string;
    label: string;
    stepModel: any;
    type: string;
    callback: Function;
}

const InputSelectElement = (props: InputSelectProps) => {
    const {formatMessage} = useIntl();
    const {
        text,
        label,
        stepModel,
        type,
        callback
    } = props;

    const thisInput = stepModel.inputs.get(type);
    const thisInputValue = thisInput ? thisInput.get('inputValue') : '';

    const data = thisInput ? thisInput.toJSON() : null;

    let resetDummyOption = null;
    if (!thisInputValue && stepModel.get('hasSubmits') === true) {
        resetDummyOption = formatMessage({
            id: 'general.please-select'
        });
    }

    const [state, setState] = useState({
        inputValue: thisInputValue,
        valid: true,
        error: ''
    });

    const toSubmitStepCabinNumberDeck = (event: any) => {
        const thisSelectValue = event.target.value;
        const stepsToSubmit = [];
        const stepData: StepDataType = {
            'id': stepModel.get('id'),
            'content': {
                [type]: thisSelectValue
            }
        };
        if (stepModel && stepModel.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
            setState({
                inputValue: thisSelectValue,
                valid: true,
                error: ''
            });
            callback(stepsToSubmit);

            if (app && app.trackController) {
                const label = (event && event.target && event.target.options && event.target.options[event.target.options.selectedIndex]) ? event.target.options[event.target.options.selectedIndex].innerText : null;
                app.trackController.eventTracking({
                    action: `Wunschkabine_${text}`,
                    label: label || thisSelectValue,
                    ga4clickLabel: `content.button.wunschkabine-${label || thisSelectValue}`
                });
            }
        } else {
            setState({
                inputValue: thisSelectValue,
                valid: false,
                error: 'nö'
            });
        }
    };

    useEffect(() => {
        setState({
            inputValue: thisInputValue,
            valid: true,
            error: ''
        });
    }, [thisInputValue]);

    const disabled = false;

    return (
        <>
            {data ?
                <div className={`form-group ${state.valid === false ? 'has-errors' : ''}`}>
                    <label htmlFor={`input-element-${data.id}`}>{text}</label>
                    <div className="select-element">
                        <select className="form-element is-light"
                            id={`input-element-${data.id}`}
                            disabled={disabled}
                            onChange={toSubmitStepCabinNumberDeck}
                            value={state.inputValue}
                        >
                            {data.options ?
                                <optgroup label={label}>
                                    {resetDummyOption ?
                                        <option value="">{resetDummyOption}</option>
                                        : ''}
                                    {data.options.map((option: { displayText: string, id: string, isBlocked: boolean }, key: number) => {
                                        return (
                                            <option key={key} value={option.id} disabled={option.isBlocked}>
                                                {option.displayText}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                                : <option>{label}</option>}

                        </select>
                    </div>
                    <div className="form-error">{state.error}</div>
                </div>
                : ''}
        </>
    );
};

